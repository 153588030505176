.yield-settings {
	.yield-settings-wrapper {
		background: $background-color;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

		.input-wrapper {
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: 1fr 1fr 1fr;
			padding: 1rem;
		}

		.button-wrapper {
			display: flex;
			justify-content: space-around;
			padding: 1rem 0;
		}
	}
}
