.context-menu {
  background: $secondary-color;
  border: 2px solid $secondary-color;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.4rem;
  position: absolute;
  z-index: 1000;
  width: max-content;

  .menu-choice {
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    color: $text-color-invert;
    justify-content: space-between;
    padding: 0 0.5rem;

    .icon {
      color: $text-color-invert;
      font-size: 1.2rem;
      margin-right: 1rem;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    span {
      width: max-content;
    }
  }
}
