// These are the colors we had available during the silo project this summer. They might very well be edited during the project but they should be defined here in order to be accesable to all scss-files.

$primary-color: #3aaa35;
$secondary-color: #007c2f;
$background-color: #f7f6f6;
$background-color-alt: #ffffff;
$kick-color-blue: #009fe3;
$kick-color-orange: #ea8d00;
$text-color: #575756;
$text-color-invert: #ffffff;
$box-color: #ededed;
$info-yellow: #f0e4b4;
