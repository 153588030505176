.feedSmartStandAlone .nav-link {
	font-size: 1.5rem;
}

.navbar {
	.nav-link {
		cursor: pointer;
		user-select: none;

		&:hover {
			color: black;
		}

		&.active {
			font-weight: bold;
		}
	}
}
