.add-remove-feeds {
	display: grid;
	grid-template-columns: 46% 1fr 46%;
	grid-gap: 10px;

	.left-name {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	.left-side {
		grid-column: 1/2;
		grid-row: 2/3;
	}

	.right-name {
		display: flex;
		justify-content: space-between;
		grid-column: 3/4;
		grid-row: 1/2;
	}

	.right-side {
		grid-column: 3/4;
		grid-row: 2/3;
	}
}
