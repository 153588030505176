.header{
    margin-bottom: 12px;
    display: flex;
    width: 100%;

    .header-title{
        flex-grow: 1;
    }

    .hide-show-dropdown{
        min-width: 200px;
    }

    .read-only-lock{
        display: flex;
        padding: 5px 30px;
        align-items: center;
        
        &.highlight-bg{
            background-color: $info-yellow;
            .lock-icon{
                background-color: $info-yellow;
            }
        }

        .lock-icon{
            border: none;
            background-color: white;
            display: flex;
 
            .read-only-text{
                color: $text-color;
                margin-right: 10px;
            }
            
            .read-only-icon{
                    color: #333;
                }
  
        }
    }

    .reset-wrapper{
        margin-right: 30px;
        button{
            border: none;
            background-color: white;
            display: flex;
            align-items: center;
        }
        .reset-icon{
            color: #333;
        }
    }

    .filter-columns-checkbox{
        margin: 10px 5px;
        cursor: pointer;
		display: flex;
		align-items: center;

        span{
            margin-left: 5px;
        }

		label{
		  zoom:0.5;
		}
    }

}

