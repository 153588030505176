.feed-mix {
	.header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.menu {
			cursor: pointer;
			color: #333;
			&:hover {
				color: #555;
			}
		}
	}

    .sub-header{
        display: flex;

		.customer-name{
			justify-self: flex-end;
		}
    }

	.wrapper {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: 1fr 1fr auto;
		padding: 0;
	}

	.button-wrapper {
		flex-grow: 1;
		display: flex;
		justify-content: flex-end;
		justify-self: flex-end;
	
		.createFeedButton {
			order: 2;
			margin-left: auto !important;
			margin-right: 1rem;
		}
		.cancelFeedButton {
			order: 2;
			background-color: #555;
			border-color: #555 !important;
			align-self: flex-end;
		}
	}

	.yield-level-info{
		font-style: italic;
	}

	.read-only{
		cursor: not-allowed;
	}
	.read-only table > tbody{
		pointer-events: none;
	}
	
	.text-center {
		text-align: center;
	  }

	.mix-table-title {
		margin-top: 0;
	}
}