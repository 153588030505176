.loader {
  text-align: center;
  min-height: 500px;
  height: auto;
  width: 100%;
  background: #3aaa35;
  vertical-align: middle;
}

.svg-loading-icon {
  width: 100px;
  height: 100px;
  margin: 20px;
  display:inline-block;
  margin-top:16%;
}