$table-text-color: $text-color;
$table-background-uneven: $background-color;
$table-background-even: $background-color-alt;
.feedSmartStandAlone th,
.feedSmartStandAlone td {
	font-size: 1.3rem;
}

table {
	border-collapse: collapse;
	color: $table-text-color;
	position:relative;

	thead {
		
		th {
			background-color: $primary-color;
			color: $text-color-invert;
		}
		position: sticky;
		top: 0;
		z-index: 10;
	}

	td {

		padding: 0.6em;
		text-align: left;
	}
	
	tbody {
		
		tr {
			&:nth-child(even) {
				background: $table-background-uneven !important;
			}
		}	
	}
	
}

// Loop for setting nth child css
// Use: Tables with a sticky column (available options sticky-col-1 to sticky-col-10).
// Example: <table class="sticky-col-3"></table>
@for $i from 1 through 10 {
	.sticky-col-#{$i} {
		tr.selected {
			td:nth-child(#{$i}) {
				position: sticky;
				background: rgba(0, 0, 0, 0.6) !important;
				color:#fff;
				left:0px;
				text-align:center;
				white-space: nowrap;
			}
		}	
	}
}	

.lm__table-wrapper {
	overflow-x: visible;
	max-height: calc(78vh - 233px);
	width: 100%;
	overflow-y: scroll;
}	

