.side-menu {
	background: $background-color;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	padding: 1rem;
	position: absolute;
	right: 0;
	top: 0;
	width: 20rem;
	z-index: 1000;

	&:hover {
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	}

	.menu-choices {
		.material-icons {
			color: #333;
			float: right;
			cursor: pointer;
		}
	}

	.menu-choice {
		font-size: 1.2rem;
		margin: 0;

		&:hover {
			color: black;
			cursor: pointer;
		}
	}
}
