.lm-dropdown-div {
  .lm-dropdown {
    max-width: 20vw;
  }

  .dropdown-check-list {
    display: inline-block;
    position: relative;
    min-width: 24ch !important;
    .anchor {
      position: relative;
      cursor: pointer;
      padding: 5px 50px 5px 10px;
      border: 1px solid #ccc;

      &:after {
        position: absolute;
        content: "";
        border-left: 2px solid black;
        border-top: 2px solid black;
        padding: 5px;
        right: 10px;
        top: 20%;
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }

      &:active:after {
        right: 8px;
        top: 21%;
      }
    }

    .items {
      padding: 4px;
      display: none;
      margin: 0;
      border: 1px solid #ccc;
      border-top: none;
      position: absolute;
      background-color: #fff;
      overflow-y: scroll;
      height: 250px;
      z-index: 100;
      width: 100%;

      li {
        list-style: none;
      }

      &.visible {
        display: flex;
        flex-direction: column;
      }
    }

    &.disabled{
      .anchor{
        background-color: #e6e6e6!important;
        color: #999!important;
        border: 2px solid #999!important;
      }
    }
  }
}
