.feed-plan-overview {
  .sub-header {
    display: grid;

    .feed-plan-name,
    .customer-name {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .feed-plan-name {
      justify-self: center;
    }

    .customer-name {
      justify-self: flex-end;
    }
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0;

  

    .feed-diet-table .lm__table-wrapper {
      overflow-y: visible !important;
      max-height: 100% !important;
    }

    .feed-diet-table .lm__table-wrapper table {
      overflow: auto !important;
    }

    .tables-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;

      .table-header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 12px;
      }

      #legend {
        .legend-list {
          display: flex;
          margin-bottom: 15px;
        }
        .min-legend {
          background-color: $kick-color-orange;
          border-radius: 50%;
          height: 12px;
          width: 12px;
          display: inline-flex;
          vertical-align: middle;
        }

        .max-legend {
          background-color: $kick-color-blue;
          border-radius: 50%;
          height: 12px;
          width: 12px;
          display: inline-flex;
          vertical-align: middle;
        }

        .label {
          margin-left: 4px;
          margin-right: 8px;
          font-family: "Source Sans Pro", sans-serif;
          color: #666666;
          font-size: 12px;
        }

        .legendValue {
          height: 25px;
        }
      }

      .feed-diet-table > div > table > tbody {
        td{
          &:first-child{
            width: 5ch;
          }
          &:nth-child(n+3){
            width: 78px;
          }
        }
      }

      .control-parameter-table > div > table {
        tbody{
          td{
            &:nth-child(n+2){
              width: 78px;
            }
          }
        }    
      }

      &.open {
        .table-lock-wrapper {
          display: flex;

          .table-lock-icon {
            align-self: flex-start;
            padding: 0.6em;
            border: none;
            background-color: $secondary-color;

            span {
              color: rgba(0, 0, 0, 0.2);
              font-size: 16px !important;
              transition: color 0.3s ease-in;
            }
          }
        }
      }

      &.read-only {
        cursor: not-allowed;

        .table-lock-wrapper {
          display: flex;

          .table-lock-icon {
            align-self: flex-start;
            padding: 0.6em;
            border: none;
            background-color: $secondary-color;

            span {
              font-size: 16px !important;
              transition: color 0.3s ease-in;
            }
          }
        }

        &:hover {
          .table-header,
          .graphs-header {
            pointer-events: none;
          }

          table {
            tbody {
              pointer-events: none;
              tr {
                background: rgba(0, 0, 0, 0.2) !important;
              }
            }
          }

          .table-lock-wrapper {
            .table-lock-icon {
              span {
                transition: color 0.3s ease-in;
                color: white;
              }
            }
          }
        }
      }
    }

    .graphs-wrapper {
      flex: 1;
      min-width: 50%;
      .graphs-header {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
