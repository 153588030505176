* {
    box-sizing: border-box;
    font-family: LMSans, sans-serif;
    margin: 0;
}

html.feedSmartStandAlone {

    color: $text-color;
    font-size: 0.625rem;

    body {
        background-color: $background-color;
        margin: 20px;
        overflow: auto;
    }

    a {
        text-decoration: none;
    }

}


.feedsmartContainer {
  
    color: $text-color;
    font-family: LMSans, sans-serif;

     a {
         text-decoration: none;
     }

    .logo {

        &.full-width {
            max-width: 330px;
        }

        &.symbol {
            max-width: 60px;
            display: none;
        }

        @media only screen and (max-width: 639px) {
            &.full-width {
                display: none;
            }

            &.symbol {
                display: block;
            }
        }
    }

    @import "assets/scss/defaults/_iconDefaults";
    @import "assets/scss/defaults/_tableDefaults";
}

