.customer-feeds {
  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .customer-name {
    margin-left: auto;
    order: 2;
    margin-right: 1rem;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .sticky-header {
    &.lang-sv {
      tr {
        th {
          &:nth-child(1) {
            left: 0;
          }
          &:nth-child(2) {
            left: 57px !important;
          }
          &:nth-child(3) {
            left: 120px !important;
          }
          &:nth-child(4) {
            left: 185px !important;
          }
        }

        td {
          &:nth-child(1) {
            left: 0;
          }
          &:nth-child(2) {
            left: 57px !important;
          }
          &:nth-child(3) {
            left: 120px !important;
          }
          &:nth-child(4) {
            left: 185px !important;
          }
        }
      }
    }

    tr {
      th {
        &:nth-child(1) {
          left: 0;
          position: sticky;
        }
        &:nth-child(2) {
          left: 65px;
          position: sticky;
        }
        &:nth-child(3) {
          left: 127px;
          position: sticky;
        }
        &:nth-child(4) {
          left: 192px;
          position: sticky;
        }
      }

      td {
        &:nth-child(1) {
          left: 0;
          position: sticky;
        }
        &:nth-child(2) {
          left: 65px;
          position: sticky;
        }
        &:nth-child(3) {
          left: 127px;
          position: sticky;
        }
        &:nth-child(4) {
          left: 192px;
          position: sticky;
        }
      }

      &:nth-child(even) {
        td {
          &:nth-child(-n + 4) {
            background: rgba($background-color, 0.85);
            z-index: 1;
          }
        }
      }
      &:nth-child(odd) {
        td {
          &:nth-child(-n + 4) {
            background: rgba($background-color-alt, 0.85);
            z-index: 1;
          }
        }
      }
    }
  }

  table > tbody > tr > :nth-child(4) {
    min-width: 244px;
  }
  table > tbody > tr > :nth-child(69) {
    min-width: 100px;
  }

  .table-wrapper {
    @include table-wrapper-base-styling;
  }

  .read-only {
    cursor: not-allowed;
  }
  .read-only table > tbody {
    pointer-events: none;
  }

  .text-center {
    text-align: center;
  }

  .sub-header-buttons {
    display: flex;

    .left-wrapper {
      height: 0;

      .customer-name {
        margin: 0 !important;
      }
    }

    .buttons-wrapper {
      flex-grow: 1;
    }
  }

  .show-only-active-checkbox{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 10px;
    label{
      zoom:0.5;
    }
}
}
