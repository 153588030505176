.spinner {
  &.small {
    width: 50px;
  }

  &.medium {
    width: 100px;
  }

  &.big {
    width: 250px;
  }
}

