.choose-customer {
	.header {
		margin-bottom: 0 !important;
	}

	.buttons-wrapper {
		margin-inline: 1rem;
		color: #333;
		.btn {
			margin: 1rem;
		}
	}


	.search-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 15px;
	}

	.search-form {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		width: 36%;
		input[type=text] {
			padding: 10px;
			font-size: 16px;
			border: 1px solid grey;
			float: left;
			width: 80%;
			background: #f1f1f1;
		}

		button {
			float: left;
			width: 16%;
			max-width: 55px;
			min-width: 38px;
			display: flex;
			justify-content : space-between;
			align-content: center; 
			font-size: 1rem;
			padding:10px !important;
			margin-top: 5px;
		}

		button:hover {
			background: #0b7dda;
		}
	}

	.table-wrapper {
		overflow: scroll;
		height: 400px;
		width: 100%;
	}

	.loading-wrapper {
		display: flex;
		align-items: center;

		p	{
			margin-bottom: 0;
		}
	}

	.refresh-list-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 5px;

		.refresh-list-btn {
			display: flex;
			align-items: center;
			outline: none;
			background: none;
			border: none;
			color: $text-color;
			padding: 0;
		}

		.refresh-icon {
			font-size: large;
			color: #333;
			margin-right: 5px;
		}
	}

	.show-only-users-checkbox{
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		margin-top: 10px;
		label{
		  zoom:0.5;
		}
	}
}
