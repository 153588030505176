.modal {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    width: 45%;

    .cancelButton {
      background-color: #555;
      border-color: #555 !important;
      margin-top: 20px;
      width: 15vw;
    }

    .saveButton {
      margin-top: 20px;
      width: 15vw;
    }

    .text-center {
      text-align: center;
    }
  }

  .modal-header {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    width: 45%;

    .close {
      color: #aaaaaa;
      float: right;
      font-size: 24px;
      font-weight: bold;
    }
    
    .close:hover,
    .close:focus {
      color: #555;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
  }
}