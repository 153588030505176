.login-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .text {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .login-error {
    color: red;
    font-size: 1.6rem;
    font-style: italic;
  }
}
