.graphs-wrapper {
  .card-row {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-2 
  {
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 100px;
    padding: 1rem;
    margin: 0.4rem;
    margin-top: 2rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    vertical-align: top;
  }


  &.read-only{
    cursor: not-allowed;
    .graphs-header{
      pointer-events: none;
    }
  }
}

.dropdown-item{
  display:flex;

  label{
      flex: 1;
      margin-left: 5px;
  }
}

.dropdown-checkbox{
  margin: 10px 5px;
  cursor: pointer;

  span{
    margin-left: 5px;
  }

  label{
    zoom:0.5;
  }
}