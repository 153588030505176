.print{

.header{
    margin-bottom: 12px;
}

.section{
    margin:30px 0px;
}

.print-for{
    display: flex;

 .print-for-radio{
     display:flex;
     flex-direction: column;
     justify-content: flex-end;
     margin-top: 25px;
 }

    .print-for-table{
        flex-grow: 1;

        div > table > tbody > tr > td {
            padding: 20px 10px !important;
            width: 20%;
        }
    }

    label{
        padding: 5px 10px;
    }
}

.print-submit{
    display: grid;

    .print-comment{
        resize: none;
    }

    .print-button-wrapper {
        display: flex;
        justify-self: flex-end;
    }

}

.print-checkbox{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

}