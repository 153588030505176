@mixin flex-column-base-styling {
	display: flex;
	flex-direction: column;
}

.feed-plan-settings {
	.text-center {
		text-align: center;
	}
	.mt-20 {
		margin-top:20px;
	}
	.wrapper {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: 1fr 1fr auto;
		padding: 0;

		.plan-config {
			@include flex-column-base-styling;
		}

		.animal-data {
			@include flex-column-base-styling;
		}

		.btn {
			align-self: flex-end;
		}
	}
	.button-wrapper {
		width:100%;
		max-width: 89em;
		margin-left: auto;
		padding-right: 1em;
		padding-left: 1em;
		margin-top: 1rem;
		margin-right:2rem;
		display: flex;
		.createFeedButton {
			order: 2;
			margin-left: auto !important;
			margin-right: 1rem;
		}
		.saveFeedButton {
			order: 2;
			margin-left: auto !important;
		}
		.cancelFeedButton {
			order: 2;
			background-color: #555;
			border-color: #555 !important;
		}
	}
}
