.feed-plan-calculation {
	.header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.menu {
			cursor: pointer;
			color: #333;
			&:hover {
				color: #555;
			}
		}
	}
	
	.text-center {
		text-align: center;
	  }

	.button-wrapper {
		display: flex;
		justify-content: flex-end;
	}  

}

@import "pages/feed-plan-calculation/scss/FeedPlanOverview";
@import "pages/feed-plan-calculation/scss/FeedPlanSettings";
@import "pages/feed-plan-calculation/scss/PreferredYieldSettings";
@import "pages/feed-plan-calculation/scss/GraphsWrapper";
@import "pages/feed-plan-calculation/scss/FeedPlanOverviewAddRemove";
