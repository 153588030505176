.table {
  thead {
    th {
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      padding: 0.6em;

      &:hover {
        background: $secondary-color;
      }

      &:active {
        color: $primary-color;
      }

      .content {
        user-select: none;
      }
      &:first-child {
        padding-left: 0.8em;
        text-align: left;
      }
      .inside-content {
        display: flex;
        font-size: small;
      }
    }
  }

  tbody {
    position: relative;

    td {
      text-align: left;
      padding: 0.4em 0.6em;
      &:first-child {
        padding-left: 0.7em;
      }
      span {
        width: 24px;
        height: 24px;
      }
      span.highlight-min {
        background-color: $kick-color-orange !important;
        border-radius: 0;
        color: $text-color-invert;
        padding-left: 4px;
        padding-right: 4px;
      }
      span.highlight-max {
        background-color: $kick-color-blue !important;
        border-radius: 0;
        color: $text-color-invert;
        padding-left: 4px;
        padding-right: 4px;
      }

      &.highlight-change {
        background-color: $info-yellow;
      }
    }

    .empty-table-data {
      height: 3rem;

      .message {
        z-index: 10;
      }
    }

    tr {
      white-space: nowrap;

      // Hover effect when a row hovered.
      &:hover {
        background: rgba(0, 0, 0, 0.1) !important;

        td {
          &.highlight-change {
            filter: brightness(85%);

            &:hover {
              filter: none;
            }
          }
        }
      }

      // Background color change when a row is selected.
      &.selected {
        background: rgba(0, 0, 0, 0.2) !important;
      }

      transition: 0.3s ease-in;
      transition-property: clip;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      clip: rect(auto, auto, 0, auto);

      .table-data {
        transition: 0.3s ease-in;
        padding: 2px 10px;

        .content {
          border-radius: 5px;
          cursor: default;

          &.empty {
            display: inline-block;
            width: 100%;

            &:hover {
              border: 1px solid $text-color;
            }
          }

          &.editable {
            cursor: pointer;
            &:hover {
              color: $kick-color-blue;
            }
          }
        }

        .form {
          padding: 0;

          input {
            position: relative;
            left: -10px;
            min-width: 6ch;
            width: 100%;
            border: none !important;
            margin: 0;
            padding: 0;
            height: 1rem;
          }
        }

        input {
          margin: 0;
        }
      }
    }
  }

  .feed-mix td:nth-child(2) {
    pointer-events: all;
  }

  .feed-mix td:nth-child(3) {
    pointer-events: all;
  }

  .material-icons {
    color: $text-color;
    cursor: pointer !important;

    &:hover {
      opacity: 0.5;
    }
  }

  .table-checkbox {
    zoom: 0.5;
  }

  .table-data-span {
    display: block;
    width: 100%;
  }
}
