.standard-feeds {
	.header {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	.sub-header{
		margin-bottom: 15px;
		display: flex;
		justify-content: flex-end;
	}

	.spinner-wrapper {
		flex-grow: 1;
	}

	.buttons-wrapper {

		.btn {
			margin: 1rem;
		}
	}

	.sticky-header{
		tr {
			th{
				&:nth-child(1){
					left: 0;
					position: sticky;
				}
				&:nth-child(2){
					left: 65px;
					position: sticky;
				}
				&:nth-child(3){
					left: 112px;
					position: sticky;
				}

			}

			td{
				&:nth-child(1){
					left: 0;
					position: sticky;
				}
				&:nth-child(2){
					left: 65px;
					position: sticky;
				}
				&:nth-child(3){
					left: 112px;
					position: sticky;
				}
			}
			
			&:nth-child(even) {
				td{
					&:nth-child(-n+3){
						background: rgba($background-color, .85);
						z-index: 1;
					}
				}
			}
			&:nth-child(odd) {
				td{
					&:nth-child(-n+3){
						background: rgba($background-color-alt, .85);
						z-index: 1;
					}
				}
			}
			
		}
	}

	.table-wrapper {
		@include table-wrapper-base-styling;
	}

	.read-only{
		cursor: not-allowed;
	}
	.read-only table > tbody{
		pointer-events: none;
	}

	iframe {
		display: none;
	}

	.showCow {
		position: absolute;
		width: 100%;
		height: 20%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 10000;
		opacity: 0;
		animation: moo 3s ease-in-out;

	}

	@keyframes moo {
		0%{
			opacity: 0;
			transform: rotate(0deg);
		}

		50%{
			opacity: 1;
			transform: rotate(25deg);
		}

		100%{
			opacity: 0;
		}
	}

}
