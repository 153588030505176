.customer-overview {
  .wrapper{
    display: flex;
    flex-wrap: wrap-reverse;

    .feed-plans{
      flex: 2;
    }

    .customer-info{
      flex: 1;
      width: 30%;
      min-width: 20vw;
      margin-right: 25px;
    }
  }

  .card-row {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .card {
    flex: 1;
    display: inline-block;
    min-height: 100px;
    width: 100%;
    padding: 1rem 1.2rem 1rem 1.6rem;
    margin: 0.4rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    vertical-align: top;

    p{
      word-break: break-all;
    }
  }

  .right-card{
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .interestCodes {
    .interestDescription {
      display: inline-block;
      width: 20vw;
      font-weight: 500;
      margin-bottom: 2vw;
    }
    .interestNumber {
      float: right;
    }
  }

  .productionProfile {
    display:flex;
    .productionDescription {
      display: inline-block;
      width: 20vw;
      font-weight: 500;
      margin-bottom: 2vw;
    }
    .productionNumber {
      float: right;
    }
  }

  .header {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
  .sub-header{
    display: grid;

    .customer-name{
      justify-self: end;
    }
  }

	.buttons-wrapper {
		margin-inline: 1rem;

		.btn {
			margin: 1rem;
		}
	}

  .table-wrapper {
		overflow: scroll;
		height: 400px;
		width: 100%;
	}
  .text-center {
    text-align: center;
  }

}