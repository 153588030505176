.ErrorPageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .errorHeader {
        font-size: 2em;
    }

    .errorMessage {
        font-size: 1.5em;
    }
}